$(document).ready(function(){

 	$('#btn-upload').change(function(){
    	$('#upload-file').val($(this).val());
 	});

 
	if( $('.date-input')[0] ){
		if(! Modernizr.touch){
	       	$( ".date-input" ).attr( "type", "text" );
	        $('.date-input').datetimepicker({
	            format: 'yyyy-mm-dd'
	        });
	    } 
	}

    // Form Tel-Country Flags
    if( $('.phone-input')[0] ){
    	$(".phone-input").intlTelInput();
    }


});

